define("discourse/plugins/poll/discourse/services/polls-hot-service", ["exports", "@ember/service", "discourse/lib/ajax"], function (_exports, _service, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class pollsHotService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "store", [_service.inject]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    get polls() {
      return (0, _ajax.ajax)("/polls/poll_list_home.json").then(response => {
        //console.log(response);
        return response;
      });
    }
  }
  _exports.default = pollsHotService;
});