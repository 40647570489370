define("discourse/plugins/poll/discourse/components/poll-list-item-widget", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/service", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/did-update", "discourse-common/helpers/i18n", "@ember/helper", "@ember/template", "discourse/widgets/glue", "discourse-common/lib/get-owner", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "@ember/application", "discourse/helpers/loading-spinner", "discourse/plugins/poll/discourse/components/poll", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _service, _object, _didInsert, _didUpdate, _i18n, _helper, _template, _glue, _getOwner, _pluginApi, _decorators, _application, _loadingSpinner, _poll3, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PollListItemWidgetComponent extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "pollsService", [_service.service]))();
    #pollsService = (() => (dt7948.i(this, "pollsService"), void 0))(); //@service router;
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))(); //@service store;
    static #_4 = (() => dt7948.g(this.prototype, "inFrontpage", [_tracking.tracked], function () {
      return false;
    }))();
    #inFrontpage = (() => (dt7948.i(this, "inFrontpage"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "pollUpdated", [_tracking.tracked], function () {
      return false;
    }))();
    #pollUpdated = (() => (dt7948.i(this, "pollUpdated"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "pollAttributes", [_tracking.tracked], function () {
      return {};
    }))();
    #pollAttributes = (() => (dt7948.i(this, "pollAttributes"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "poll", [_tracking.tracked], function () {
      return this.args.poll;
    }))();
    #poll = (() => (dt7948.i(this, "poll"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "post", [_tracking.tracked], function () {
      return null;
    }))();
    #post = (() => (dt7948.i(this, "post"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "vote", [_tracking.tracked], function () {
      return null;
    }))();
    #vote = (() => (dt7948.i(this, "vote"), void 0))();
    constructor() {
      super(...arguments);
      this.appEvents.on("poll:voted", this, this.handlePollVotedEvent);
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.appEvents.off("poll:voted", this, this.handlePollVotedEvent);
    }
    // get poll() {
    //   return this.args.poll;
    // }
    updatePollAndVotes(newPoll, newPost, newVote) {
      this.poll = newPoll;
      this.post = newPost;
      //this.votes = newVotes;
      this.vote = newVote;
    }
    get post() {
      return this.postForPoll(this.poll);
    }
    postForPoll(poll) {
      return this.pollsService.postsForPoll(poll);
    }
    async buildPollAttrs(poll, post, vote) {
      const pollGroupableUserFields = this.siteSettings.groupable_user_fields;
      const pollName = poll.name;
      const pollObj = _object.default.create(poll);
      const titleElement = poll.post_topic_title;
      let pollPost = post;
      // NOTE: 매번 가져와야 새로 그림. 원인 파악중.
      //if(!pollPost) {
      pollPost = await this.postForPoll(poll);
      //}
      if (!vote) {
        const polls_votes = pollPost.get('polls_votes') || {};
        vote = polls_votes[pollName] || [];
      }
      const attrs = {
        id: `${pollName}-${pollPost.id}`,
        poll: pollObj,
        post: pollPost,
        vote,
        hasSavedVote: vote != null && vote.length > 0,
        titleHTML: titleElement,
        groupableUserFields: (pollGroupableUserFields || "").split("|").filter(Boolean)
      };
      // console.log('attrs', attrs);
      return attrs;
    }
    // @action
    // async loadPollAttributes() {
    //   this.isFetchingPollAttributes = true;
    //   try {
    //     const attr = await Promise.all(
    //       this.polls.map((pollHash) =>
    //         this.buildPollAttrs(pollHash)
    //       )
    //     );
    //     setProperties(this, {
    //       pollAttributes: attr,
    //       isFetchingPollAttributes: false,
    //     });
    //   } catch (error) {
    //     console.error("Error loading poll attributes:", error);
    //     this.isFetchingPollAttributes = false;
    //   }
    // }
    async updatePollAttributes() {
      this.pollUpdated = false;
      const updatedAttributes = await this.buildPollAttrs(this.poll, this.post, this.vote);
      this.pollAttributes = updatedAttributes; // pollAttributes 업데이트
      this.pollUpdated = true;
    }
    async handlePollVotedEvent(poll, post, vote) {
      if (this.pollAttributes && this.pollAttributes.id === `${poll.name}-${poll.post_id}`) {
        this.updatePollAndVotes(poll, post, vote);
        await this.updatePollAttributes();
      } else {
        console.warn("No matching poll found for the event");
      }
    }
    async onDidInsert() {
      await this.updatePollAttributes();
    }
    static #_10 = (() => dt7948.n(this.prototype, "onDidInsert", [_object.action]))();
    static #_11 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class='poll-list-item-widget' {{didInsert this.onDidInsert}}>
          {{#if this.poll}}
            {{#if this.pollUpdated}}
            <div class="poll-outer" data-poll-name={{this.poll.name}} data-poll-type={{this.poll.type}}>
              <div class="poll">
                <Poll @attrs={{this.pollAttributes}} />
              </div>
            </div>
            {{else}}
            {{loadingSpinner size="small"}}
            {{/if}}
          {{else}}
            {{loadingSpinner size="small"}}
          {{/if}}
        </div>
      
    */
    {
      "id": "4VurGPd/",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"poll-list-item-widget\"],[4,[32,0],[[30,0,[\"onDidInsert\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"poll\"]],[[[41,[30,0,[\"pollUpdated\"]],[[[1,\"        \"],[10,0],[14,0,\"poll-outer\"],[15,\"data-poll-name\",[30,0,[\"poll\",\"name\"]]],[15,\"data-poll-type\",[30,0,[\"poll\",\"type\"]]],[12],[1,\"\\n          \"],[10,0],[14,0,\"poll\"],[12],[1,\"\\n            \"],[8,[32,1],null,[[\"@attrs\"],[[30,0,[\"pollAttributes\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[32,2],null,[[\"size\"],[\"small\"]]]],[1,\"\\n\"]],[]]]],[]],[[[1,\"        \"],[1,[28,[32,2],null,[[\"size\"],[\"small\"]]]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/poll/discourse/components/poll-list-item-widget.js",
      "scope": () => [_didInsert.default, _poll3.default, _loadingSpinner.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = PollListItemWidgetComponent;
});