define("discourse/plugins/poll/discourse/controllers/admin-plugins-polls", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@glimmer/tracking", "discourse/lib/ajax", "discourse/lib/ajax-error", "I18n"], function (_exports, _controller, _object, _service, _tracking, _ajax, _ajaxError, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsPollsController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.inject]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "dialog", [_service.inject]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "toasts", [_service.inject]))();
    #toasts = (() => (dt7948.i(this, "toasts"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "creatingNew", [_tracking.tracked], function () {
      return false;
    }))();
    #creatingNew = (() => (dt7948.i(this, "creatingNew"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "polls", [_tracking.tracked], function () {
      return [];
    }))();
    #polls = (() => (dt7948.i(this, "polls"), void 0))();
    currentPage = 1;
    totalPages = null;
    total = 0;
    get sortedPolls() {
      return this.model.polls?.sortBy('updatedAt').reverse() || [];
    }
    static #_6 = (() => dt7948.n(this.prototype, "sortedPolls", [(0, _object.computed)('model.polls.@each.updatedAt')]))();
    formatDate(date) {
      if (date) {
        return moment(date).format("YYYY-MM-DD");
      }
    }
    createNew() {
      this.creatingNew = true;
      this.modal.show('admin-plugins-polls-new');
    }
    static #_7 = (() => dt7948.n(this.prototype, "createNew", [_object.action]))();
    getNumber(total, currentPage, index) {
      return total - (currentPage - 1) * 10 - index;
    }
    async loadPage(page) {
      if (page === this.currentPage) return;
      this.set("isLoading", true);
      try {
        // AJAX 요청으로 특정 페이지 데이터 가져오기
        const response = await (0, _ajax.ajax)(`/polls/poll_admin_list.json?page=${page}`);
        const {
          polls,
          total_pages,
          total
        } = response;
        this.setProperties({
          polls,
          currentPage: page,
          totalPages: total_pages,
          total: total
        });
      } catch (e) {
        console.error("Failed to load page:", e);
      } finally {
        this.set("isLoading", false);
      }
    }
    static #_8 = (() => dt7948.n(this.prototype, "loadPage", [_object.action]))();
    destroyPoll(poll) {
      this.dialog.deleteConfirm({
        message: _I18n.default.t('poll.admin.confirm_destroy'),
        didConfirm: () => {
          let poll_id = poll.id;
          return (0, _ajax.ajax)(`/polls/remove-poll/${poll.id}.json`, {
            type: 'DELETE'
          }).then(response => {
            this.toasts.success({
              duration: 3000,
              data: {
                message: _I18n.default.t('poll.admin.delete_success')
              }
            });
            this.polls = this.polls.filter(poll => poll.id !== poll_id); // Array에서 poll 제거
          }).catch(_ajaxError.popupAjaxError);
        }
      });
    }
    static #_9 = (() => dt7948.n(this.prototype, "destroyPoll", [_object.action]))();
    get paginationRange() {
      const range = [];
      const totalPages = this.totalPages || 1;
      for (let i = 1; i <= totalPages; i++) {
        range.push(i);
      }
      return range;
    }
  }
  _exports.default = AdminPluginsPollsController;
});