define("discourse/plugins/poll/discourse/routes/admin-plugins-polls", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _object, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsPolls extends _discourse.default {
    async model(params) {
      if (!this.currentUser?.admin) {
        return {
          model: null
        };
      }
      const page = params.page || 1;

      // AJAX 요청으로 데이터를 가져옵니다.
      const response = await (0, _ajax.ajax)(`/polls/poll_admin_list.json?page=${page}`);
      const {
        polls,
        total_pages,
        total
      } = response;
      return {
        polls,
        totalPages: total_pages,
        total: total
      };
    }
    setupController(controller, model) {
      controller.setProperties({
        polls: model.polls,
        totalPages: model.totalPages,
        total: model.total,
        currentPage: 1
      });
    }
  }
  _exports.default = AdminPluginsPolls;
});