define("discourse/plugins/poll/discourse/services/polls-service", ["exports", "@ember/service", "@glimmer/tracking", "discourse/lib/ajax"], function (_exports, _service, _tracking, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class pollsService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "store", [_service.inject]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "category", [_tracking.tracked], function () {
      return 4;
    }))();
    #category = (() => (dt7948.i(this, "category"), void 0))();
    setCategory(category) {
      this.set("category", category);
    }
    get polls() {
      return (0, _ajax.ajax)("/polls/poll_list.json?category=" + this.category).then(response => {
        //console.log(response);
        return response;
      });
    }
    postsForPoll(poll) {
      return this.fetchPostById(poll.post_id).then(response => {
        return response;
      });
    }
    async fetchPostById(post_id) {
      try {
        const post = await this.store.find("post", post_id);
        return post;
      } catch (error) {
        console.error("Error fetching post:", error);
      }
    }
  }
  _exports.default = pollsService;
});