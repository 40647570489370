define("discourse/plugins/poll/discourse/components/poll-list-tab-voter", ["exports", "@glimmer/component", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
    <PollListTabVoter 
      @options={{@poll.options}} 
      @preloaded_voters={{@poll.preloaded_voters}} />
  */class PollListTabVoterComponent extends _component.default {
    get voters() {
      var voters = [];
      this.args.options.forEach(option => {
        if (option.votes > 0) {
          this.args.preloaded_voters[option.id].forEach(voter => {
            voter.avatar_template = voter.avatar_template.replace('{size}', 32);
            voters.push(voter);
          });
        }
      });
      return voters.slice(0, 5);
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="voter-list">
          <ul>
            {{#each this.voters as |voter|}}
            <li>
              <img src="{{voter.avatar_template}}">&nbsp;&nbsp;{{voter.username}}
            </li>
            {{/each}}
          </ul>
        </div>
      
    */
    {
      "id": "f7xiFRf6",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"voter-list\"],[12],[1,\"\\n      \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"voters\"]]],null]],null],null,[[[1,\"        \"],[10,\"li\"],[12],[1,\"\\n          \"],[10,\"img\"],[15,\"src\",[29,[[30,1,[\"avatar_template\"]]]]],[12],[13],[1,\"  \"],[1,[30,1,[\"username\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"voter\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/poll/discourse/components/poll-list-tab-voter.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = PollListTabVoterComponent;
});