define("discourse/plugins/poll/discourse/components/poll-list-tab", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/modifier", "@ember/object", "@ember/helper", "discourse-common/helpers/i18n", "@ember/service", "@ember/render-modifiers/modifiers/did-insert", "discourse/plugins/poll/discourse/components/poll-list-tab-discussion", "discourse/plugins/poll/discourse/components/poll-list-tab-voter", "discourse/models/topic", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _modifier, _object, _helper, _i18n, _service, _didInsert, _pollListTabDiscussion, _pollListTabVoter, _topic, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // args: @poll
  class PollListTabComponent extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "pollsService", [_service.service]))();
    #pollsService = (() => (dt7948.i(this, "pollsService"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "selectedTab", [_tracking.tracked], function () {
      return "tab1";
    }))();
    #selectedTab = (() => (dt7948.i(this, "selectedTab"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "commentCount", [_tracking.tracked], function () {
      return 0;
    }))();
    #commentCount = (() => (dt7948.i(this, "commentCount"), void 0))();
    get postId() {
      return this.args.poll.post_id;
    }
    get voters() {
      return this.args.poll.voters;
    }
    get tabs() {
      let tabs = [{
        id: "tab1",
        label: (0, _i18n.default)("poll.admin.tab_overview"),
        badge: null
      }, {
        id: "tab2",
        label: (0, _i18n.default)("poll.admin.tab_data"),
        badge: null
      }, {
        id: "tab3",
        label: (0, _i18n.default)("poll.admin.tab_discussion"),
        badge: this.commentCount
      }, {
        id: "tab4",
        label: (0, _i18n.default)("poll.admin.tab_voters"),
        badge: this.voters
      }];
      return tabs;
    }
    initData() {
      //console.log("this.args.postId", this.postId);
      this.updateCommentCount(this.postId);
    }
    static #_4 = (() => dt7948.n(this.prototype, "initData", [_object.action]))();
    async updateCommentCount(postId) {
      const post = await this.pollsService.fetchPostById(postId);
      const topicId = post.topic_id;
      const topic = await _topic.default.find(topicId, {});
      let allPosts = topic.post_stream.posts;
      // firstly filter comments by reply_to_post_number
      let firstLevelPosts = allPosts.filter(
      //(p) => p.reply_to_post_number === null  // 댓글이 아닌것 개수
      p => p.post_number > 1 // that post_number is not first
      );
      this.commentCount = firstLevelPosts.length;
    }
    selectTab(tabId, event) {
      event.preventDefault();
      this.selectedTab = tabId; // 선택된 탭 변경
    }
    static #_5 = (() => dt7948.n(this.prototype, "selectTab", [_object.action]))();
    isActiveTab(selected_tab, tabId) {
      return selected_tab === tabId; // 현재 선택된 탭과 비교
    }
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="poll-list-tab-wrap" {{didInsert this.initData}}>
          <ul class="nav">
            {{#each this.tabs as |tab|}}
              <li class={{if (this.isActiveTab this.selectedTab tab.id) "active"}}>
                <a href="#" {{on "click" (fn this.selectTab tab.id)}}>{{tab.label}}
                  {{#if tab.badge}}<span class='badge'>{{tab.badge}}</span>{{/if}}
                </a>
              </li>
            {{/each}}
          </ul>
          <div class="tab-content">
            {{#if (this.isActiveTab this.selectedTab "tab1")}}
              {{{@poll.post_topic_overview}}}
            {{/if}}
            {{#if (this.isActiveTab this.selectedTab "tab2")}}
              {{{@poll.poll_data_link}}}
            {{/if}}
            {{#if (this.isActiveTab this.selectedTab "tab3")}}
              <PollListTabDiscussion @postId={{@poll.post_id}} @poll={{@poll}}/>
            {{/if}}
            {{#if (this.isActiveTab this.selectedTab "tab4")}}
              <PollListTabVoter @options={{@poll.options}} @preloaded_voters={{@poll.preloaded_voters}}  />
            {{/if}}
          </div>
        </div>
      
    */
    {
      "id": "JaA7f3Zv",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"poll-list-tab-wrap\"],[4,[32,0],[[30,0,[\"initData\"]]],null],[12],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"nav\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"tabs\"]]],null]],null],null,[[[1,\"          \"],[10,\"li\"],[15,0,[52,[28,[30,0,[\"isActiveTab\"]],[[30,0,[\"selectedTab\"]],[30,1,[\"id\"]]],null],\"active\"]],[12],[1,\"\\n            \"],[11,3],[24,6,\"#\"],[4,[32,1],[\"click\",[28,[32,2],[[30,0,[\"selectTab\"]],[30,1,[\"id\"]]],null]],null],[12],[1,[30,1,[\"label\"]]],[1,\"\\n              \"],[41,[30,1,[\"badge\"]],[[[10,1],[14,0,\"badge\"],[12],[1,[30,1,[\"badge\"]]],[13]],[]],null],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"tab-content\"],[12],[1,\"\\n\"],[41,[28,[30,0,[\"isActiveTab\"]],[[30,0,[\"selectedTab\"]],\"tab1\"],null],[[[1,\"          \"],[2,[30,2,[\"post_topic_overview\"]]],[1,\"\\n\"]],[]],null],[41,[28,[30,0,[\"isActiveTab\"]],[[30,0,[\"selectedTab\"]],\"tab2\"],null],[[[1,\"          \"],[2,[30,2,[\"poll_data_link\"]]],[1,\"\\n\"]],[]],null],[41,[28,[30,0,[\"isActiveTab\"]],[[30,0,[\"selectedTab\"]],\"tab3\"],null],[[[1,\"          \"],[8,[32,3],null,[[\"@postId\",\"@poll\"],[[30,2,[\"post_id\"]],[30,2]]],null],[1,\"\\n\"]],[]],null],[41,[28,[30,0,[\"isActiveTab\"]],[[30,0,[\"selectedTab\"]],\"tab4\"],null],[[[1,\"          \"],[8,[32,4],null,[[\"@options\",\"@preloaded_voters\"],[[30,2,[\"options\"]],[30,2,[\"preloaded_voters\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"tab\",\"@poll\"],false,[\"each\",\"-track-array\",\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/poll/discourse/components/poll-list-tab.js",
      "scope": () => [_didInsert.default, _modifier.on, _helper.fn, _pollListTabDiscussion.default, _pollListTabVoter.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = PollListTabComponent;
});