define("discourse/plugins/poll/discourse/components/poll-list-tab-discussion", ["exports", "@glimmer/component", "@ember/service", "@glimmer/tracking", "@ember/modifier", "@ember/object", "@ember/helper", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/did-update", "discourse/components/d-button", "discourse-common/helpers/i18n", "discourse/models/topic", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _tracking, _modifier, _object, _helper, _didInsert, _didUpdate, _dButton, _i18n, _topic, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // param: @postId, @poll
  class PostListTabDiscussionComponent extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "pollsService", [_service.service]))();
    #pollsService = (() => (dt7948.i(this, "pollsService"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "comments", [_tracking.tracked], function () {
      return null;
    }))();
    #comments = (() => (dt7948.i(this, "comments"), void 0))();
    static #postCache = (() => new Map())();
    initdata() {
      this.fetchPosts(this.args.postId);
    }
    static #_3 = (() => dt7948.n(this.prototype, "initdata", [_object.action]))();
    async fetchPosts(postId) {
      //const post = await this.pollsService.fetchPostById(postId);
      const post = await this._fetchPostWithCache(postId);
      const topicId = post.topic_id;
      // extract post for the topic
      const topic = await _topic.default.find(topicId, {});
      let allPosts = topic.post_stream.posts;
      // firstly filter comments by reply_to_post_number
      let firstLevelPosts = allPosts.filter(
      //(p) => p.reply_to_post_number === null  // 댓글이 아닌것 개수
      p => p.post_number > 1 // that post_number is not first
      );
      // sort by created_at
      firstLevelPosts.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      // decorate posts
      firstLevelPosts.forEach(p => {
        p.created_date = this._dateString(p.created_at);
        p.cooked_truncated = this._truncateString(this._stripHtmlTags(p.cooked), 120);
      });
      this.comments = firstLevelPosts;
    }
    _truncateString(str) {
      let len = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 40;
      if (!str) return str;else if (str.length > len) {
        return str.slice(0, len) + "...";
      }
    }
    _dateString(datetime) {
      return new Date(datetime).toISOString().slice(0, 10);
    }
    _stripHtmlTags(html) {
      return html.replace(/<\/?[^>]+(>|$)/g, "");
    }
    async _fetchPostWithCache(postId) {
      const cacheEntry = PostListTabDiscussionComponent.#postCache.get(postId);
      const now = Date.now();
      // 10분(600,000ms) 이내에 캐싱된 데이터가 있다면 사용
      if (cacheEntry && now - cacheEntry.timestamp < 600000) {
        return cacheEntry.data;
      }
      // 그렇지 않다면 새로 가져와 캐시에 저장
      const post = await this.pollsService.fetchPostById(postId);
      PostListTabDiscussionComponent.#postCache.set(postId, {
        data: post,
        timestamp: now
      });
      return post;
    }
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="post-list" {{didInsert this.initdata}}>
          <ul>
            {{#each this.comments as |post|}}
              <li>
                [{{post.created_date}}] {{post.cooked_truncated}}
              </li>
            {{/each}}
          </ul>
          <a href="{{@poll.post_url}}" class='widget-button'>
            {{i18n "poll.poll_list_widget.goto_topic"}}
          </a>
        </div>
      
    */
    {
      "id": "0lMaeHEJ",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"post-list\"],[4,[32,0],[[30,0,[\"initdata\"]]],null],[12],[1,\"\\n      \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"comments\"]]],null]],null],null,[[[1,\"          \"],[10,\"li\"],[12],[1,\"\\n            [\"],[1,[30,1,[\"created_date\"]]],[1,\"] \"],[1,[30,1,[\"cooked_truncated\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n      \"],[10,3],[15,6,[29,[[30,2,[\"post_url\"]]]]],[14,0,\"widget-button\"],[12],[1,\"\\n        \"],[1,[28,[32,1],[\"poll.poll_list_widget.goto_topic\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"post\",\"@poll\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/poll/discourse/components/poll-list-tab-discussion.js",
      "scope": () => [_didInsert.default, _i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = PostListTabDiscussionComponent;
});